import React from "react";

const HeroAnimation = () => (
  <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
    <img
      className="object-cover object-center rounded"
      alt="hero"
      src="https://thumbs.gfycat.com/EvilNextDevilfish-small.gif"
    />
  </div>
);

export default HeroAnimation;
