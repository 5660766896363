import React from "react";
import ReactTooltip from "react-tooltip";
import MiVet from "../Images/MiVetComments.gif";
import MSSQL from "../Images/MSSQL.png";
import CSharp from "../Images/C#.png";
import ASP from "../Images/ASP.png";

const BioSection = (props) => (
  <div className="container-fluid px-0">
    <div className="row pt-5">
      <div className="col-12 px-0">
        <div className="slantedDivBlue p-3">
          <div className="col-9 p-3 mt-3 mx-auto bio">
            <h4 className="text-center playfair"> Hello, my name is Donald.</h4>
            <p className="text-center">
              {" "}
              Hello! I’m a full stack software engineer who works very well in a
              team oriented environment based in Hayward CA. I enjoy
              collaborating and receiving constructive inputs to make the most
              out of the projects that I work on. I find great satisfaction in
              making my code work efficiently and always finding ways to be a
              better programmer through continuous learning and practice!
              Actively seeking employment as a Full Stack Software Engineer,
              Front End Developer, or Back End Developer.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="col-10 mx-auto roles-container">
        <div className="row text-center mb-0">
          <div className="col-12 px-1 py-5">
            <i className="fas fa-code fa-3x purple-text-wm" />
            <h4>Full Stack Developer</h4>
            <p>
              I like to create fully functional applications. This means coding
              both backend and frontend structure to create dynamic content!
              Actively seeking employment as a Full Stack Software Engineer,
              Front End Developer, or Back End Developer.
            </p>
            <br />
            <div className="row text-center">
              <div className="col-3"></div>
              <div className="col-3"></div>
              <div className="col-3"></div>
            </div>
            <p className="purple-text-wm mt-5">Technology and Langauges:</p>
            <p>ReactJS, dotnet Web Api, SQL, AWS, CSS, and more.</p>
            <br />
            <p className="purple-text-wm">More Dev Tools:</p>
            <p>VS Code</p>
            <p>VS Studio</p>
            <p>GitHub</p>
            <p>MSSQL</p>
            <div className="d-flex col-8 pt-3 mx-auto justify-content-around">
              <i data-tip data-for="react" className="fab fa-react fa-3x" />
              <ReactTooltip id="react" effect="solid">
                <span>React JS</span>
              </ReactTooltip>
              <i
                data-tip
                data-for="js"
                className="fab fa-js-square fa-3x mx-2"
              />
              <ReactTooltip id="js" effect="solid">
                <span>Javascript</span>
              </ReactTooltip>
              <img
                data-tip
                data-for="CSharp"
                className="wm-icon mx-1"
                src={CSharp}
                alt="C Sharp"
              />
              <ReactTooltip id="CSharp" effect="solid">
                <span>C#</span>
              </ReactTooltip>
              <img
                alt="ASP.NET Web Api"
                data-tip
                data-for="ASP"
                className="wm-icon mx-1"
                src={ASP}
              />
              <ReactTooltip id="ASP" effect="solid">
                <span>ASP.NET Web Api</span>
              </ReactTooltip>
              <img
                alt="Microsoft SQL Server"
                data-tip
                data-for="MSSQL"
                className="wm-icon mx-1"
                src={MSSQL}
              />
              <ReactTooltip id="MSSQL" effect="solid">
                <span>Microsoft Server SQL</span>
              </ReactTooltip>
            </div>
          </div>
          <div className="col-12 px-1 py-5">
            <i
              className="fa fa-location-arrow fa-3x purple-text-wm"
              aria-hidden="true"
            />
            <h4>Based in Hayward, Ca.</h4>
            <p>You can reach me at (510) 491-7904.</p>
            <p>
              Or send me an email at{" "}
              <a href="mailto:dmon.jragon@gmail.com?subject=Let's Talk">
                dmon.jragon@gmail.com
              </a>
            </p>
            <br />
          </div>
        </div>
      </div>

      <div className="col-10 mx-auto mb-5">
        <div className="row text-center">
          <div className="col pt-5">
            <h1 className="emphasize theme-purple">
              Check out some of my recent projects.
            </h1>
            <p>
              You can check out my code samples including ReactJS components,
              ASP.NET Controllers and SQL stored procedures{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/DJMondragon1/MiVet"
              >
                here
              </a>{" "}
              if you would like to see more.{" "}
            </p>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-s p-3">
            <img src={MiVet} alt="MiVet Gif" className="img-fluid" />
          </div>
          <div className="col p-3">
            <div style={{ borderBottom: "1px solid #9f30fa" }}>
              <h4 className="theme-purple mb-0">Mi-Vet Animal Clinic</h4>
            </div>
            <p>
              A startup based in Dexter, MI whose main goal is to promote and
              facilitate equine veterinary care! The app connects horse owners
              to vetinarians seemlessly to ensure care is never out of reach!
            </p>

            <h5
              className="theme-purple
         "
            >
              Technologies
            </h5>
            <div className="d-flex pt-3 justify-content-around">
              <i data-tip data-for="react" className="fab fa-react fa-3x" />
              <ReactTooltip id="react" effect="solid">
                <span>React JS</span>
              </ReactTooltip>
              <i
                data-tip
                data-for="js"
                className="fab fa-js-square fa-3x mx-2"
              />
              <ReactTooltip id="js" effect="solid">
                <span>Javascript</span>
              </ReactTooltip>
              <img
                data-tip
                data-for="CSharp"
                className="wm-icon mx-1"
                src={CSharp}
                alt="C Sharp"
              />
              <ReactTooltip id="CSharp" effect="solid">
                <span>C#</span>
              </ReactTooltip>
              <img
                alt="ASP.NET Web Api"
                data-tip
                data-for="ASP"
                className="wm-icon mx-1"
                src={ASP}
              />
              <ReactTooltip id="ASP" effect="solid">
                <span>ASP.NET Web Api</span>
              </ReactTooltip>
              <img
                alt="Microsoft SQL Server"
                data-tip
                data-for="MSSQL"
                className="wm-icon mx-1"
                src={MSSQL}
              />
              <ReactTooltip id="MSSQL" effect="solid">
                <span>Microsoft Server SQL</span>
              </ReactTooltip>
            </div>
            <h5
              className="theme-purple
         "
            >
              Role
            </h5>
            <p>
              I worked as a Fullstack Engineer for 4 months where I was tasked
              with making a comments and replies thread for users. Using MSSql,
              ADO.Net, and React.Js, I implemented Formik, Routing, nad Mapping
              in order to present the comments thread to the user.
            </p>
            <h5
              className="theme-purple
         "
            >
              Check It Out
            </h5>
            <p>
              You can check out the live project by clicking{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mivet.azurewebsites.net/"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="row footer mb-0 p-3">
      <div className="start-chat col-10 text-center mx-auto p-3 theme-white align-middle">
        <div className="col-xs-3 p-3">
          <h3 className="d-inline">Let's Start A Project</h3>
        </div>
        <div className="col-xs-3 p-3">
          <p className="d-inline">
            Have a project in mind? Let's bring it to life together! Send me an
            email and we can set up a time to talk.
          </p>
        </div>
        <div className="col-xs-3 p-3">
          <span className="nav-btn">
            <a
              className="btn-large waves-effect"
              href="mailto:dmon.jragon@gmail.com?subject=Let's Start A Project"
            >
              <p>Let's get started!</p>
            </a>
          </span>
        </div>
      </div>
      {/* <div className="col-10 mt-3 p-3 align-middle mx-auto text-center">
        <h4>Striving to be better everyday.</h4>
      </div> */}
    </div>
  </div>
);

export default BioSection;
